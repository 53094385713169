import { FC, useState, useEffect } from "react"
import styled from "styled-components"
import { useRouter } from "next/router"

import { routes } from "@lib/config"
import { MenuItem, ContentfulLink } from "@lib/types"

import { useUI, useSite, useCustomer, useTracking } from "@hooks/index"

import { Link } from "@components/common"
import { Sidebar, Tile, Accordion } from "@components/ui"
import SearchMobile from "@svg/search-mobile.svg"
import Cross from "@svg/cross.svg"
import NavLink from "next/link"

export default function NavSidebar(): JSX.Element {
  const { displayNavSidebar, closeNavSidebar, openLoginModal } = useUI()
  const { menusCollection, mobileSidebarBottomLinksCollection } = useSite()
  const { customer, logoutCustomer } = useCustomer()
  const { track } = useTracking()

  const router = useRouter()

  const logout = async () => {
    await router.push("/")
    await logoutCustomer()
    closeNavSidebar()
  }

  const openRewardsLauncher = () => {
    try {
      if (typeof window !== "undefined") {
        /*@ts-ignore*/
        window.StampedFn.toggleRewardsModal()
      }
    } catch (error) {}
  }

  const AccountMenuItem = {
    category: "My Account",
    linksCollection: {
      items: [
        ...routes.map(({ title, href }) => ({
          sys: {
            id: title,
          },
          title,
          entry: {
            __typename: "Account",
            slug: href,
          },
        })),
        {
          sys: {
            id: "Log out",
          },
          title: "Log out",
          onClick: logout,
        },
      ],
    },
  } as MenuItem

  const menus = menusCollection.items[0]
  const [menuItems, setMenuItems] = useState<MenuItem[]>(menus.menuItemsCollection.items)

  useEffect(() => {
    if (customer) {
      const menuItemsWithAccount = [...menus.menuItemsCollection.items, AccountMenuItem]
      setMenuItems(menuItemsWithAccount)
    } else {
      setMenuItems(menus.menuItemsCollection.items)
    }
  }, [customer])

  const tileImage = menus?.image
  const tileButton = menus?.button

  return (
    <StyledNavSidebar
      isOpen={displayNavSidebar}
      handleClose={() => {
        closeNavSidebar()
        track("Nav Closed", { category: "Menu level 1" })
      }}
    >
      <Content>
        <Header>
          <CloseButton
            onClick={() => {
              closeNavSidebar()
              track("Nav Closed", { category: "Menu level 1" })
            }}
          >
            <Cross />
          </CloseButton>
          <StyledTile image={tileImage} button={tileButton} />
          <NavLink href={`/search`}>
            <SearchField
              onClick={() => {
                closeNavSidebar()
                track("Nav Closed", { category: "Menu level 1" })
              }}
            >
              <StyledSearchMobile />
            </SearchField>
          </NavLink>
        </Header>
        <ScrollArea>
          <Accordion
            items={menuItems}
            activeIndex={0}
            trackingProperties={{ category: "Menu level 1" }}
          />
          {!customer && <AccountButton onClick={openLoginModal}>Login / Sign up</AccountButton>}
          <BottomNav>
            <BottomNavItem>
              <button
                onClick={() => {
                  closeNavSidebar()
                  openRewardsLauncher()
                }}
              >
                REWARDS
              </button>
            </BottomNavItem>
            {mobileSidebarBottomLinksCollection.items.map((link: ContentfulLink) => {
              return (
                <BottomNavItem key={link.sys.id}>
                  <Link link={link} trackingProperties={{ category: "Menu level 1" }} />
                </BottomNavItem>
              )
            })}
          </BottomNav>
        </ScrollArea>
      </Content>
    </StyledNavSidebar>
  )
}

const StyledNavSidebar = styled(Sidebar)`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Header = styled.div`
  flex-shrink: 0;
`

const CloseButton = styled.button`
  z-index: 100;
  position: absolute;
  top: 1.3rem;
  left: 1.3rem;
`

const tileAndSearchHeight = 252

const ScrollArea = styled.div`
  height: ${`calc(100vh - ${tileAndSearchHeight}px)`};
  overflow-y: scroll;
`

const StyledTile = styled(Tile)`
  height: 19.8rem;
  width: 100%;
`

const SearchField = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2.1rem;
  height: 5.4rem;
  font-size: 1.5rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.greyLight}`};

  span {
    margin-top: 0.2rem;
    opacity: 0.5;
    line-height: 3rem;
  }
`

const StyledSearchMobile = styled(SearchMobile)`
  width: 1.8rem;
  margin-right: 1.1rem;
`

const BottomNav = styled.ul`
  padding: 2.1rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0.12rem;
  line-height: 3rem;
`

const BottomNavItem = styled.li`
  button {
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.12rem;
    line-height: 3rem;
    color: black;
  }
`

const AccountButton = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.font.heading};
  font-weight: 400;
  font-size: 2rem;
  align-items: center;
  padding: 2rem;
  color: ${({ theme }) => theme.color.black};
  border-top: ${({ theme }) => `1px solid ${theme.color.greyLight}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.greyLight}`};
`
